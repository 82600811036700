<template>
  <svg width="100%" viewBox="-14 0 61 39" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.898438 36H1.77734V32.792H5.64453V36H6.52344V28.9541H5.64453V32.001H1.77734V28.9541H0.898438V36ZM10.376 36.0928C11.875 36.0928 12.8027 35.0576 12.8027 33.373V33.3633C12.8027 31.6738 11.875 30.6436 10.376 30.6436C8.87695 30.6436 7.94922 31.6738 7.94922 33.3633V33.373C7.94922 35.0576 8.87695 36.0928 10.376 36.0928ZM10.376 35.3408C9.37988 35.3408 8.81836 34.6133 8.81836 33.373V33.3633C8.81836 32.1182 9.37988 31.3955 10.376 31.3955C11.3721 31.3955 11.9336 32.1182 11.9336 33.3633V33.373C11.9336 34.6133 11.3721 35.3408 10.376 35.3408ZM14.1211 36H14.9707V32.7383C14.9707 31.9961 15.4932 31.3955 16.1816 31.3955C16.8457 31.3955 17.2754 31.8008 17.2754 32.4258V36H18.125V32.6162C18.125 31.9473 18.6084 31.3955 19.3408 31.3955C20.083 31.3955 20.4395 31.7812 20.4395 32.5576V36H21.2891V32.3623C21.2891 31.2588 20.6885 30.6436 19.6143 30.6436C18.8867 30.6436 18.2861 31.0098 18.0029 31.5664H17.9248C17.6807 31.0195 17.1826 30.6436 16.4697 30.6436C15.7812 30.6436 15.2832 30.9707 15.0488 31.5371H14.9707V30.7363H14.1211V36ZM24.9707 36.0928C26.2109 36.0928 26.9629 35.3896 27.1436 34.6768L27.1533 34.6377H26.3037L26.2842 34.6816C26.1426 34.999 25.7031 35.3359 24.9902 35.3359C24.0527 35.3359 23.4521 34.7012 23.4277 33.6123H27.2168V33.2803C27.2168 31.708 26.3477 30.6436 24.917 30.6436C23.4863 30.6436 22.5586 31.7568 22.5586 33.3828V33.3877C22.5586 35.0381 23.4668 36.0928 24.9707 36.0928ZM24.9121 31.4004C25.6885 31.4004 26.2646 31.8936 26.3525 32.9336H23.4424C23.5352 31.9326 24.1309 31.4004 24.9121 31.4004Z"/>
    <path d="M25.3555 10.4388C25.3547 10.4383 25.3542 10.4376 25.3536 10.437L15.5626 0.647278C15.1452 0.229797 14.5903 0 14.0001 0C13.4099 0 12.8551 0.229797 12.4375 0.647278L2.65158 10.4321C2.64828 10.4354 2.6448 10.4388 2.64169 10.4421C1.78467 11.304 1.78614 12.7024 2.6459 13.5621C3.0387 13.955 3.55731 14.1824 4.11199 14.2064C4.1347 14.2086 4.15741 14.2097 4.1803 14.2097H4.57035V21.4142C4.57035 22.84 5.73063 24 7.15661 24H10.9872C11.3756 24 11.6904 23.6851 11.6904 23.2969V17.6484C11.6904 16.9979 12.2198 16.4687 12.8704 16.4687H15.1298C15.7805 16.4687 16.3097 16.9979 16.3097 17.6484V23.2969C16.3097 23.6851 16.6245 24 17.0129 24H20.8435C22.2696 24 23.4297 22.84 23.4297 21.4142V14.2097H23.7916C24.3816 14.2097 24.9365 13.9799 25.3542 13.5623C26.2149 12.7013 26.2152 11.3004 25.3555 10.4388ZM24.3596 12.568C24.2078 12.7198 24.006 12.8035 23.7916 12.8035H22.7265C22.3381 12.8035 22.0233 13.1182 22.0233 13.5066V21.4142C22.0233 22.0646 21.4941 22.5938 20.8435 22.5938H17.7161V17.6484C17.7161 16.2226 16.556 15.0624 15.1298 15.0624H12.8704C11.4443 15.0624 10.284 16.2226 10.284 17.6484V22.5938H7.15661C6.50616 22.5938 5.97674 22.0646 5.97674 21.4142V13.5066C5.97674 13.1182 5.66195 12.8035 5.27355 12.8035H4.22681C4.21582 12.8027 4.20502 12.8022 4.19385 12.802C3.98436 12.7983 3.78786 12.7152 3.64063 12.5678C3.32749 12.2547 3.32749 11.7451 3.64063 11.4318C3.64082 11.4318 3.64082 11.4316 3.641 11.4315L3.64155 11.4309L13.4323 1.64154C13.5839 1.48975 13.7855 1.40625 14.0001 1.40625C14.2146 1.40625 14.4162 1.48975 14.568 1.64154L24.3565 11.4289C24.358 11.4304 24.3596 11.4318 24.3611 11.4333C24.6726 11.7469 24.6721 12.2554 24.3596 12.568Z" />
  </svg>

</template>

<script>
export default {
name: "HomeIcon"
}
</script>

<style scoped>

</style>
