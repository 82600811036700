export default  {

    'confused-person-sticker' : '<svg width="100%" viewBox="0 0 81 84" >\n' +
        '    <g filter="url(#filter0_d)">\n' +
        '      <path d="M74.6437 0H53.4572C51.2125 0 49.3928 1.81968 49.3928 4.06437V25.2509C49.3928 27.4956 51.2125 29.3153 53.4572 29.3153H57.2586C58.3655 29.3153 59.2627 30.2124 59.2627 31.3193V34.7468C59.2627 35.467 60.1741 35.779 60.6156 35.2102L64.6106 30.0623C64.9762 29.591 65.5394 29.3153 66.1358 29.3153H74.6439C76.8885 29.3153 78.7082 27.4956 78.7082 25.2509V4.06437C78.7082 1.81968 76.8884 0 74.6437 0Z" fill="#F7E365"/>\n' +
        '      <path d="M74.6437 0H70.2529C72.4976 0 74.3173 1.81968 74.3173 4.06437V25.2509C74.3173 27.4956 72.4976 29.3153 70.2529 29.3153H74.6437C76.8884 29.3153 78.7081 27.4956 78.7081 25.2509V4.06437C78.7082 1.81968 76.8884 0 74.6437 0Z" fill="#F3D332"/>\n' +
        '      <path d="M48.3484 79.9998H5.54956L6.13847 71.9164C6.49909 66.9695 10.2228 62.9252 15.1245 62.1584L18.5138 61.627C20.237 61.3587 21.5056 59.8741 21.5056 58.1316V45.6902H34.2223V58.1316C34.2223 59.8741 35.4926 61.3587 37.2142 61.627L40.6051 62.1584C45.505 62.9252 49.2304 66.9695 49.5895 71.9164L50.0456 78.1748C50.1173 79.1606 49.3368 79.9998 48.3484 79.9998Z" fill="#FAA68E"/>\n' +
        '      <path d="M18.582 69.3436C18.7292 68.7774 19.2405 68.382 19.8255 68.382H25.1676C28.0614 68.382 30.4073 66.0361 30.4073 63.1424V60.9399C30.4073 60.4241 30.8255 60.0058 31.3412 60.0058H34.7626C34.4203 59.4591 34.222 58.816 34.222 58.1314V54.4297H22.9894C22.3883 54.4297 22.0362 53.7536 22.3812 53.2622L24.6325 50.0455C24.9828 49.5472 25.1414 48.9704 25.1222 48.4024C25.1012 47.5869 24.7126 46.7905 24.0017 46.2747L23.9442 46.2329C23.2092 45.6996 22.3098 45.5712 21.5053 45.8002V58.1311C21.5053 59.8736 20.2367 61.3583 18.5134 61.6266L15.1242 62.158C12.8328 62.5164 10.7991 63.5914 9.24565 65.1397L5.61394 79.1112L5.54956 79.9998H15.8119L18.582 69.3436Z" fill="#E09380"/>\n' +
        '      <path d="M48.3484 79.9998H5.54956L6.13847 71.9163C6.49909 66.9695 10.2228 62.9251 15.1245 62.1584L18.5138 61.627C18.7542 61.5887 18.9859 61.5276 19.2072 61.4457C21.3 63.7092 24.3737 65.1398 27.7994 65.1398C31.2444 65.1398 34.332 63.6953 36.4264 61.4092C36.6739 61.5103 36.9387 61.5834 37.214 61.627L40.605 62.1584C45.5048 62.9251 49.2303 66.9695 49.5893 71.9163L50.0454 78.1748C50.117 79.1593 49.3363 79.9998 48.3484 79.9998Z" fill="#ACD980"/>\n' +
        '      <path d="M19.8256 68.382H25.1678C27.4386 68.382 29.3715 66.9371 30.0987 64.9166C29.3556 65.0607 28.5875 65.1396 27.7995 65.1396C24.3737 65.1396 21.3 63.709 19.2073 61.4456C17.1592 62.2051 12.9017 61.4965 9.24612 65.1399L5.6144 79.1115L5.54956 79.9997H15.8119L18.582 69.3435C18.7292 68.7773 19.2405 68.382 19.8256 68.382Z" fill="#8CC951"/>\n' +
        '      <path d="M50.0453 78.1748L49.5892 71.9163C49.4114 69.4668 48.4051 67.2131 46.7998 65.4698C43.1437 61.4993 38.6037 62.1686 36.5972 61.4729C36.5894 61.4703 36.5819 61.467 36.5741 61.4643C36.5244 61.4467 36.4747 61.4289 36.4262 61.4092C36.0703 61.7976 35.6836 62.1597 35.2734 62.4967L36.1969 62.6414C41.0967 63.4081 44.8222 67.4525 45.1812 72.3993L45.6372 78.6576C45.6753 79.1816 45.4712 79.6627 45.1245 79.9999H48.3481C49.3367 79.9998 50.1172 79.1606 50.0453 78.1748Z" fill="#8CC951"/>\n' +
        '      <path d="M41.5722 72.6526C40.9053 72.6526 40.365 73.1931 40.365 73.8598V79.9998H42.7795V73.8598C42.7794 73.1931 42.2389 72.6526 41.5722 72.6526Z" fill="#8CC951"/>\n' +
        '      <path d="M63.2843 20.0171C62.6175 20.0171 62.0772 19.4767 62.0772 18.81V14.6796C62.0772 14.0129 62.6176 13.4725 63.2843 13.4725H64.0504C65.8059 13.4725 67.234 12.0443 67.234 10.2889C67.234 8.53341 65.8059 7.10529 64.0504 7.10529C62.2951 7.10529 60.867 8.53341 60.867 10.2889C60.867 10.9556 60.3265 11.4961 59.6598 11.4961C58.993 11.4961 58.4526 10.9556 58.4526 10.2889C58.4526 7.20201 60.9637 4.69092 64.0506 4.69092C67.1375 4.69092 69.6487 7.20216 69.6487 10.2889C69.6487 13.2272 67.3731 15.6442 64.4917 15.8698V18.81C64.4915 19.4765 63.9511 20.0171 63.2843 20.0171Z" fill="#544F56"/>\n' +
        '      <path d="M63.2843 24.6247C62.6175 24.6247 62.0771 24.0842 62.0771 23.4175V23.0639C62.0771 22.3972 62.6176 21.8567 63.2843 21.8567C63.951 21.8567 64.4915 22.3972 64.4915 23.0639V23.4175C64.4915 24.0842 63.951 24.6247 63.2843 24.6247Z" fill="#544F56"/>\n' +
        '      <path d="M30.1412 5.37524H24.7445C18.4635 5.37524 12.9845 8.80696 10.079 13.8976C6.03966 14.9557 3.09529 18.6891 3.22169 23.0638L3.68779 39.1844H50.9064V26.1405C50.9064 14.6723 41.6094 5.37524 30.1412 5.37524Z" fill="#655E67"/>\n' +
        '      <path d="M13.9333 41.8981H5.61609C3.61906 41.8981 2 40.2792 2 38.2821V37.1483C2 35.1513 3.6189 33.5322 5.61609 33.5322H13.9333V41.8981Z" fill="#FAA68E"/>\n' +
        '      <path d="M40.7009 41.8981H49.0181C51.0151 41.8981 52.6342 40.2792 52.6342 38.2821V37.1483C52.6342 35.1513 51.0153 33.5322 49.0181 33.5322H40.7009V41.8981Z" fill="#FAA68E"/>\n' +
        '      <path d="M46.8491 36.3344C46.8491 48.2528 34.8249 55.0947 27.7999 55.0947C20.7768 55.0947 8.75073 48.2528 8.75073 36.3344C8.75073 28.7211 10.867 22.9591 14.2912 19.0951C15.0617 18.2257 16.419 18.2344 17.1885 19.1048C17.9713 19.9901 18.7956 20.8358 19.6932 21.6066C20.7504 22.5147 21.9984 23.3435 23.3898 23.3587C24.3187 23.3699 25.2818 22.8719 25.7823 22.1276C26.1966 21.5112 27.0188 21.321 27.6918 21.6354C32.128 23.7085 37.0229 24.9791 41.9305 25.2536C43.9039 25.3641 45.5825 26.7565 46.0294 28.6818C46.5628 30.9805 46.8491 33.5322 46.8491 36.3344Z" fill="#FAA68E"/>\n' +
        '      <path d="M46.0293 28.6819C45.6742 27.1519 44.5401 25.9612 43.0995 25.4802C43.5296 27.6098 43.7595 29.9405 43.7595 32.4724C43.7595 44.6427 31.4812 51.6289 24.3078 51.6289C20.9719 51.6289 16.5309 50.1164 12.7344 47.2744C16.8686 52.3367 23.3417 55.0947 27.8 55.0947C34.825 55.0947 46.8492 48.2528 46.8492 36.3344C46.8492 33.5324 46.5629 30.9807 46.0293 28.6819Z" fill="#E09380"/>\n' +
        '      <path d="M12.3702 79.9998L15.8435 66.639C15.9919 66.0681 16.5074 65.6695 17.0974 65.6695H22.4313C25.325 65.6695 27.671 63.3235 27.671 60.4298V58.2273C27.671 57.7115 28.0891 57.2934 28.6049 57.2934H33.8132C35.3552 57.2934 36.6603 56.0718 36.6725 54.5298C36.6796 53.7492 36.3658 53.0434 35.8571 52.5347C35.3517 52.0293 34.6547 51.7175 33.8846 51.7175H20.253C19.6519 51.7175 19.2999 51.0414 19.6449 50.55L21.8961 47.3333C22.2465 46.835 22.4049 46.2581 22.3858 45.6901C22.3649 44.8747 21.9763 44.0783 21.2654 43.5625L21.2079 43.5206C19.9376 42.5989 18.1741 42.8794 17.2629 44.1426C15.6547 46.3731 8.58539 56.1607 7.88664 57.1295L2.31837 78.5508C2.12774 79.2841 2.68118 79.9998 3.43883 79.9998H12.3702Z" fill="#F9B1A0"/>\n' +
        '      <path d="M22.2458 37.6026C22.893 37.6026 23.4177 37.0779 23.4177 36.4307V35.1223C23.4177 34.4751 22.893 33.9504 22.2458 33.9504C21.5987 33.9504 21.074 34.4751 21.074 35.1223V36.4307C21.074 37.0779 21.5987 37.6026 22.2458 37.6026Z" fill="#444145"/>\n' +
        '      <path d="M39.7578 37.6026C40.4052 37.6026 40.9297 37.0779 40.9297 36.4307V35.1223C40.9297 34.4751 40.4052 33.9504 39.7578 33.9504C39.1106 33.9504 38.5859 34.4751 38.5859 35.1223V36.4307C38.5859 37.0779 39.1106 37.6026 39.7578 37.6026Z" fill="#444145"/>\n' +
        '      <path d="M28.9806 43.3683C30.5784 42.4362 32.0664 42.4362 33.6642 43.3683C33.8498 43.4765 34.053 43.5281 34.2536 43.5279C34.6567 43.5279 35.049 43.3197 35.2669 42.9464C35.5929 42.3873 35.404 41.6698 34.845 41.3437C32.5405 39.9994 30.1042 39.9994 27.7997 41.3437C27.2406 41.6698 27.0517 42.3875 27.3778 42.9464C27.7041 43.5056 28.4217 43.6943 28.9806 43.3683Z" fill="#444145"/>\n' +
        '    </g>\n' +
        '    <defs>\n' +
        '      <filter id="filter0_d" x="0" y="0" width="80.7082" height="83.9999" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">\n' +
        '        <feFlood flood-opacity="0" result="BackgroundImageFix"/>\n' +
        '        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>\n' +
        '        <feOffset dy="2"/>\n' +
        '        <feGaussianBlur stdDeviation="1"/>\n' +
        '        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>\n' +
        '        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>\n' +
        '        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>\n' +
        '      </filter>\n' +
        '    </defs>\n' +
        '  </svg>',

    'false-sticker' : '<svg data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512 width="><path fill="#E24C4B" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>',

    'lets-go-sticker' : '<svg width="100%" viewBox="0 0 81 84">\n' +
        '    <g filter="url(#filter0_d)">\n' +
        '      <path d="M40.8509 61.273C54.1498 61.273 64.9307 51.4192 64.9307 39.264C64.9307 27.1087 54.1498 17.2549 40.8509 17.2549C27.552 17.2549 16.7711 27.1087 16.7711 39.264C16.7711 51.4192 27.552 61.273 40.8509 61.273Z" fill="#F6EE86"/>\n' +
        '      <path d="M40.8511 17.2548C27.3881 17.2548 16.3131 27.4236 16.7875 40.0338C20.6653 40.4746 24.5861 39.5417 27.8777 37.2715C34.7958 32.5004 45.3936 32.4921 52.3234 37.2713C56.1326 39.8985 60.6913 40.676 64.9234 39.7882C65.2374 27.5201 54.4768 17.2548 40.8511 17.2548Z" fill="#EFD176"/>\n' +
        '      <path d="M12.2119 33.3534L7.11974 29.9456C0.560532 25.556 0.252564 16.04 6.49224 11.2069C16.1932 3.69265 28.2164 0 40.1006 0C51.9836 0 64.0065 3.6914 73.709 11.2069C79.9487 16.0401 79.6407 25.556 73.0813 29.9456L67.9892 33.3532C64.0731 35.9738 58.944 35.9712 55.0651 33.296C50.9797 30.4785 45.7004 28.8609 40.1008 28.8609C34.5011 28.8609 29.2219 30.4784 25.1364 33.296C21.2572 35.9715 16.128 35.974 12.2119 33.3534Z" fill="#EDA1AB"/>\n' +
        '      <path d="M17.7765 28.0207L9.18758 17.7848C8.77149 17.289 8.83617 16.5498 9.33195 16.1339C9.82758 15.7178 10.567 15.7823 10.9829 16.2782L18.8258 25.6251C19.5104 25.051 20.5054 24.2101 21.4249 23.4295C21.9182 23.0106 22.6577 23.0712 23.0766 23.5645C23.4955 24.0579 23.4351 24.7974 22.9416 25.2162C19.479 28.1556 19.3729 28.217 19.2605 28.282C18.7682 28.5668 18.1422 28.4566 17.7765 28.0207Z" fill="white"/>\n' +
        '      <path d="M35.737 20.2478C35.5695 19.6226 34.9264 19.2518 34.3017 19.419L30.1578 20.5295L29.0868 16.5329L32.8417 15.5268C33.4668 15.3593 33.8378 14.7167 33.6703 14.0915C33.5028 13.4665 32.8601 13.0957 32.235 13.2629L28.4801 14.269L27.4092 10.2725L31.5529 9.16216C32.1781 8.99466 32.549 8.35201 32.3815 7.72685C32.214 7.10185 31.5715 6.73107 30.9462 6.89826L25.6705 8.31185C25.0453 8.47935 24.6744 9.122 24.8419 9.74716L28.1969 22.2681C28.365 22.8953 29.0095 23.2635 29.632 23.0968L34.9078 21.6832C35.5334 21.5156 35.9043 20.8729 35.737 20.2478Z" fill="white"/>\n' +
        '      <path d="M58.0059 27.5245C56.413 26.1879 55.4173 24.2183 55.3424 22.2556C55.3177 21.6089 55.822 21.0647 56.4687 21.04C57.1188 21.0187 57.6599 21.5197 57.6843 22.1664C57.7344 23.4789 58.4177 24.8107 59.5123 25.729C60.7102 26.7342 62.1073 26.6497 62.814 26.0592C63.5369 25.4551 63.5137 24.3426 62.7501 23.0067C62.1979 22.0409 60.8268 19.482 60.7227 19.1372C59.7924 16.0755 62.9171 13.2133 66.2638 14.65C69.466 16.0248 70.1158 19.5365 70.1421 19.6854C70.2538 20.3229 69.8277 20.9303 69.1902 21.0422C68.5536 21.1539 67.9488 20.7301 67.8344 20.0956C67.8243 20.0429 67.3485 17.6664 65.339 16.8036C63.8415 16.1603 62.6732 17.2705 62.9435 18.3773C63.1346 18.7642 63.8947 20.2865 64.7846 21.8434C65.8363 23.6833 66.2655 26.2293 64.3163 27.8576C62.6683 29.2351 59.9613 29.1656 58.0059 27.5245Z" fill="white"/>\n' +
        '      <path d="M50.6971 8.53824L43.7187 6.6684C43.0934 6.50106 42.4509 6.87184 42.2834 7.49715C42.1159 8.12231 42.4868 8.7648 43.112 8.93246L45.4553 9.56027L42.3728 21.0646C42.2053 21.6898 42.5762 22.3323 43.2014 22.4999C43.8276 22.6673 44.4693 22.2959 44.6367 21.6712L47.7192 10.167L50.0906 10.8025C50.7168 10.9698 51.3585 10.5984 51.5259 9.97371C51.6932 9.34824 51.3223 8.70574 50.6971 8.53824Z" fill="white"/>\n' +
        '      <path d="M54.1616 16.8267C53.573 16.5575 53.3141 15.8621 53.5833 15.2737L56.5984 8.67982C56.8675 8.09123 57.5628 7.83216 58.1514 8.10138C58.74 8.3706 58.9989 9.06591 58.7297 9.6545L55.7145 16.2484C55.4442 16.8398 54.7473 17.0946 54.1616 16.8267Z" fill="white"/>\n' +
        '      <path d="M31.8393 51.8249C27.4317 51.8249 23.8456 48.2389 23.8456 43.8311C23.8456 39.4233 27.4315 35.8374 31.8393 35.8374C33.441 35.8374 34.987 36.3097 36.3095 37.2033C36.8457 37.5657 36.9868 38.2941 36.6245 38.8304C36.2623 39.3668 35.5338 39.5077 34.9974 39.1454C34.0638 38.5146 32.9717 38.1811 31.8393 38.1811C28.724 38.1811 26.1893 40.7157 26.1893 43.8311C26.1893 46.9466 28.7239 49.4811 31.8393 49.4811C33.7976 49.4811 36.0517 48.2825 36.4831 45.003H33.647C32.9998 45.003 32.4751 44.4783 32.4751 43.8311C32.4751 43.1839 32.9998 42.6593 33.647 42.6593H37.7284C38.3756 42.6593 38.9002 43.1839 38.9002 43.8311C38.9001 48.5377 35.9967 51.8249 31.8393 51.8249Z" fill="#8DAEE1"/>\n' +
        '      <path d="M49.7015 51.9087C45.2938 51.9087 41.7079 48.3227 41.7079 43.9149C41.7079 39.5071 45.2938 35.9212 49.7015 35.9212C54.1093 35.9212 57.6952 39.5071 57.6952 43.9149C57.6952 48.3227 54.1093 51.9087 49.7015 51.9087ZM49.7015 38.2648C46.586 38.2648 44.0516 40.7993 44.0516 43.9148C44.0516 47.0302 46.5862 49.5648 49.7015 49.5648C52.8168 49.5648 55.3515 47.0302 55.3515 43.9148C55.3515 40.7995 52.8169 38.2648 49.7015 38.2648Z" fill="#8DAEE1"/>\n' +
        '      <path d="M48.0411 63.5982H43.3789C42.7875 63.5982 42.3034 63.1278 42.2864 62.5366L42.0269 53.5149C41.9895 52.2188 40.3419 51.6918 39.5595 52.7258L29.5342 65.9746C28.7241 67.0452 29.4878 68.5806 30.8303 68.5806H35.4925C36.0839 68.5806 36.568 69.051 36.585 69.6422L36.8445 78.6639C36.8819 79.96 38.5295 80.487 39.3119 79.453L49.3373 66.2042C50.1472 65.1336 49.3836 63.5982 48.0411 63.5982Z" fill="#EDA1AB"/>\n' +
        '      <path d="M36.935 56.1932L29.5333 65.9747C28.7231 67.0453 29.4869 68.5808 30.8294 68.5808H35.4915C36.0829 68.5808 36.567 69.0513 36.584 69.6424L36.8436 78.6641C36.8808 79.9602 38.5286 80.4872 39.3109 79.4531L45.2081 71.66C37.3793 70.1388 36.0872 61.7191 36.935 56.1932Z" fill="#E7889D"/>\n' +
        '    </g>\n' +
        '    <defs>\n' +
        '      <filter id="filter0_d" x="0" y="0" width="80.2012" height="84" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">\n' +
        '        <feFlood flood-opacity="0" result="BackgroundImageFix"/>\n' +
        '        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>\n' +
        '        <feOffset dy="2"/>\n' +
        '        <feGaussianBlur stdDeviation="1"/>\n' +
        '        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>\n' +
        '        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>\n' +
        '        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>\n' +
        '      </filter>\n' +
        '    </defs>\n' +
        '  </svg>',

    'question-mark-sticker' : '  <svg data-icon="question" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"  width="100%"><path fill="#E24C4B" d="M202.021 0C122.202 0 70.503 32.703 29.914 91.026c-7.363 10.58-5.093 25.086 5.178 32.874l43.138 32.709c10.373 7.865 25.132 6.026 33.253-4.148 25.049-31.381 43.63-49.449 82.757-49.449 30.764 0 68.816 19.799 68.816 49.631 0 22.552-18.617 34.134-48.993 51.164-35.423 19.86-82.299 44.576-82.299 106.405V320c0 13.255 10.745 24 24 24h72.471c13.255 0 24-10.745 24-24v-5.773c0-42.86 125.268-44.645 125.268-160.627C377.504 66.256 286.902 0 202.021 0zM192 373.459c-38.196 0-69.271 31.075-69.271 69.271 0 38.195 31.075 69.27 69.271 69.27s69.271-31.075 69.271-69.271-31.075-69.27-69.271-69.27z"></path></svg>',

    'tick-sticker' : '<svg data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg"  width="100%" viewBox="0 0 512 512" >' +
          '<path fill="#05DA77" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" > </path>' +
        '</svg>',

    'top-sticker' : ' <svg width="100%" viewBox="0 0 84 84">\n' +
        '    <g filter="url(#filter0_d)">\n' +
        '      <path d="M73.5001 80H10.5001C5.74994 80 2 76.2501 2 71.5001V8.50008C2 3.74994 5.74994 0 10.5001 0H73.5001C78.2501 0 82.0002 3.74994 82.0002 8.50008V71.5001C82 76.2501 78.2501 80 73.5001 80Z" fill="#D34178"/>\n' +
        '      <path d="M66.5 0C63.4999 13.0001 55.7499 27 43.75 39.0001C31 51.75 15.75 59.75 2 62.25V8.50008C2 3.74994 5.74994 0 10.5001 0H66.5Z" fill="#E84E89"/>\n' +
        '      <path d="M52.5001 30.7499H49.2501V43.2499C49.2501 44.4999 48.2501 45.4999 47.0001 45.4999H37.0001C35.7501 45.4999 34.7501 44.4999 34.7501 43.2499V30.7499H31.5001C29.75 30.7499 28.7501 28.7499 29.75 27.2499L40.2501 13.25C41.2501 12 43 12 43.7501 13.25L54.25 27.2499C55.25 28.75 54.25 30.7499 52.5001 30.7499Z" fill="white"/>\n' +
        '      <path d="M26 53.5V67.25H21.9999V53.5H17.2501V50.25H30.7501V53.5H26Z" fill="white"/>\n' +
        '      <path d="M47.5 65C45.7501 66.7499 43.75 67.5 41.0001 67.5C38.5002 67.5 36.2502 66.7499 34.5001 65C32.75 63.2499 32.0001 61.2501 32.0001 58.7499C32.0001 56.2498 32.7502 54.2499 34.5001 52.4998C36.25 50.7498 38.25 49.9999 41.0001 49.9999C43.5001 49.9999 45.7501 50.7499 47.5002 52.4998C49.2502 54.2497 50.0001 56.2498 50.0001 58.7499C50.0001 61.2501 49.2501 63.2499 47.5 65ZM46 58.7499C46 57.25 45.5001 56 44.5001 54.7498C43.5001 53.7498 42.2501 53.2499 41.0001 53.2499C39.5002 53.2499 38.2502 53.7498 37.5001 54.7498C36.5001 55.7499 36.0002 56.9998 36.0002 58.7499C36.0002 60.2499 36.5001 61.4998 37.5001 62.75C38.5002 63.75 39.7501 64.2499 41.0001 64.2499C42.5001 64.2499 43.75 63.75 44.5001 62.75C45.5001 61.5 46 60.25 46 58.7499Z" fill="white"/>\n' +
        '      <path d="M65 51.75C66.25 52.75 66.7501 54.2499 66.7501 56.4999C66.7501 58.5 66.2502 60.2499 65 61.2499C63.7501 62.2499 62 62.7498 59.2501 62.7498H57.0001V67.5H53.2501V50.5H59.2501C62 50.25 63.7501 50.7499 65 51.75ZM62.2501 58.5C62.75 58 63.0002 57.25 63.0002 56.25C63.0002 55.2499 62.7502 54.4999 62.0002 54.2499C61.5002 53.75 60.5002 53.75 59.2502 53.75H57.0002V59.5H59.5002C60.75 59.25 61.75 58.9999 62.2501 58.5Z" fill="white"/>\n' +
        '    </g>\n' +
        '    <defs>\n' +
        '      <filter id="filter0_d" x="0" y="0" width="84.0002" height="84" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">\n' +
        '        <feFlood flood-opacity="0" result="BackgroundImageFix"/>\n' +
        '        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>\n' +
        '        <feOffset dy="2"/>\n' +
        '        <feGaussianBlur stdDeviation="1"/>\n' +
        '        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>\n' +
        '        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>\n' +
        '        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>\n' +
        '      </filter>\n' +
        '    </defs>\n' +
        '  </svg>',

}

